@import '../../2-routes/0-app/variables.scss';
.message
{
    position: absolute;
    top: 50%;
    right: 5vw;
    transform: translate(0%, -50%);
    
    z-index: 11;
    
    padding: 1vw;
    
    min-width: 21vw;
    border-radius: 2vw;
    
    text-align: center;
    color: $white;
    background-color: $black;
    a 
    {
        color: $white;
    }
  
}
.loading-message 
{
    display: flex;
    justify-content: center;
    padding: 0.4vw;

    min-width: 22vw;
}
.mini-game
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    margin: auto;

    width: 45vw;
    height: 100vh;
    font-family: 'Inter', sans-serif;
    .start
    {
        cursor: pointer;
        
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
        z-index: 1;

        border-radius: 1.5vw;
        border: 1px solid $black;
        height: 3.5vw;
        width: 20vw;

        font-size: 1.3vw;
        color: $black;
        background-color: $white;
        transition: all ease 0.5s;
        &:hover 
        {
            background: $important;
            color: #faf9f2;
            border:1.5px solid $white;
        }
    }
    .game-over 
    {
        position: absolute;
        
        display: flex;
        flex-direction: column;
        align-items: center;

        z-index: 100;

        width: 30vw;
        height: 20vw;

        border-radius: 2vw;
        background-color: $white;
        transition: opacity ease 1s;
        .title
        {
            margin-top: 3vw;
            font-size: 2vw;
        }
        .score 
        {
            margin: 1vw;
        }
        .difficulter
        {
            margin-top:1vw;
        }
        .close
        {
            cursor: pointer;
            margin-top: 3vw;
            
            height:3vw;
            width: 60%;

            border-radius: 0.5vw;
            border: 1px solid $black;
            color: $white;
            font-weight: 400;
            background-color: #242038;
            transition: all ease 0.5s;
            &:hover 
            {
                background-color: transparent;
                color: $black;
            }
        }
    }
    .hide 
    {
        opacity: 0;
        display: none;
    }
    // score, vie etc.. 
    .data 
    {
        position: absolute;    
        top: 8.25vw;
        left: 2.5vw;
        #score, .vie, .difficulter
        {
            display: flex;
            align-items: center;

            height: 3vw;
            font-size: 1.3vw;
        }
        .difficulter 
        {
            margin-top: 0.75vw;
            flex-direction: column;
            align-items: baseline;
            select 
            {
                margin-top: 1vw;

                width: 10vw;
                border: 1px solid black;
                border-radius: 0.2vw;
            
                font-size: 1vw;
                color: $black;
                background-color: transparent;
            }
        }
    }
    .container 
    {
        display: flex;
        flex-wrap: wrap;

        margin-top: 3vw;
        height: 35vw;
        width: 35vw;
        border-radius: 1vw;
        
        .carre 
        {
            flex: 1;
            min-width: 33%;
            border: 1px solid $white;
            background-color: $black;

            &:nth-child(1)
            {
                border-radius: 1vw 0 0 0;
                border-top:none ;
                border-left:none ;
            }
            &:nth-child(2) {border-top:none ;}
            &:nth-child(3)
            {
                border-radius: 0 1vw  0 0;
                border-top:none ;
                border-right:none ;
            }
            &:nth-child(4) {border-left:none ;}
            &:nth-child(6) {border-right:none ;}
            &:nth-child(7)
            {
                border-radius: 0 0 0 1vw;
                border-bottom:none ;
                border-left:none ;
            }
            &:nth-child(8) {border-bottom:none ;}
            &:nth-child(9)
            {
                border-radius: 0 0 1vw 0;
                border-bottom:none ;
                border-right:none ;
            }
        }
        .actif
        {
            background-color: $important;

            cursor: pointer;
        }
    }
}