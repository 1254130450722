@import '../0-app/variables.scss';

.download-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 18.5vw;
  top: 8vw;

  z-index: 1000;

  height: 3.5vw;
  width: 3.5vw;
  border-radius: 100%;
  background-color: $important;
  i {
    color: $white;
    font-size: 1.3vw;
  }

  /* Ajoutez ces styles pour la transition */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    /* Ajoutez ces styles pour le léger mouvement au survol */
    transform: translate(0, -2px);
  }

  &.clicked {
    opacity: 0;
    pointer-events: none; /* Désactive les événements sur le bouton invisible */
    transform: scale(0.8); /* Facultatif : ajoutez un effet de réduction lors de la disparition */
  }
}


.no-data
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    padding: 1vw;
    
    border-radius: 1.5vw;
    
    background-color: $important;
    color: $white;
}

.pdf, .graph-container
{
    position: relative;
    margin: 7vw auto 0;
    padding: 2vw;
    
    width: 61vw;
    border-radius: 1vw;
    
    background-color: $black;
    color: $white;
    div
    {
        line-height: 1.8vw;
        font-size: 1.2vw;
    }
    
    .section-title 
    {
        margin: 2vw 0;
        font-size: 2vw;
        text-align: center;
        background-color: #7d426f;
        padding: 0.4vw 0;
        border-radius: 0.2vw;
    }
    .user_subject 
    {
        justify-content: center;
        font-size: clamp(13px, 2vw, 100px);
        text-align: center;
        margin: 1vw auto 4vw;
        max-width: 80%;
        line-height: 2.5vw;
    }
    .cadre 
    {
        padding: 1vw;
        margin: 2vw 0;
        border: 1px solid $white;
        background-color: #ffecd1;
        color: $black;
        border-radius: 1vw;
        transition: all ease 0.3s;
        .title 
        {
            margin-bottom: 0.75vw;
        }
        &:hover 
        {
            transform: translate(-0.3vw, -0.3vw);
            z-index: -1;
        }
        .disclaimer 
        {
            margin-top: 0 !important;

            z-index: 2000;
            
            width: 100%;
            line-height: 1.5vw !important;

            font-size: 1vw !important;
            text-align: left !important;
            font-style: italic;
        }
    }
    .chiffre 
    {
        font-family: "Luxurious Roman", serif;
    }
    .ecart 
    {
        margin-top: 2vw;
    }
    .content 
    {
        text-align: justify;
    }
    .content strong {
        font-weight: bold;
    }
      
    .title 
    {
        display: inline-block;
        justify-content: center;

        padding: 0.5vw 1vw;
        border-radius: 2vw;
        font-size: 1.5vw;
        font-weight: 500;
        color: $white;
        background-color: #0B6E4F;
    }
    .score 
    {
        background-color: #c55a4d;
    }
    .colonne 
    {
        flex-direction: column !important;
    }
    .violet 
    {
        margin-top: 3vw;
        background-color: #7d426f;
    }
    .title.violet {
        margin-bottom: 2vw; 
        border-radius: 0.3vw;

    }
    .gros 
    {
        position: relative;
        font-size: 1.3vw;
        margin-left: 0.5vw;
    }
    .note 
    {
        font-weight: 600;
    }
}