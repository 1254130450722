@import '../../0-app/variables.scss';




.menu-page 
{
    display: flex;
    flex-direction: column;
    align-items:center;
    .menu-title 
    {
        font-size: 3.4vw;
        font-weight: 700;
        margin-bottom: 6vw;
        .blue 
        {
            color: $important;
        }
    }
    .menu-container 
    {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        .selected 
        {
            background-color: $white !important;
            color: black !important;
        }
    
        .menu.left
        {
            left: 30%;
        }
        .menu.right
        {
            left: 50%;
        }
        .menu 
        {
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.1);
    
            padding: 1.5vw 2.5vw;
            width: 19vw;
            
            border: 2px solid $important;
            background-color: #f6f8fa;
            border-radius: 2vw;
            transition: all ease 0.3s;
            .menu-subtitle 
            {
                margin-bottom: 1.25vw;
                font-size: 1.25vw;
                font-weight:700;
                .red 
                {
                    color: #ff5757;
                }
            }
            ul 
            {
                display: flex;
                justify-content: space-around;
                width: 100%;
                color: $white;
                li
                {
                    cursor: pointer;
                    padding: 0.5vw 0vw;
                    width: 5vw;
                    text-align: center;
                    border-radius: 2vw;
                    font-weight: 600;
                    transition: all ease 0.3s;
                }
                .filiere-item 
                {
                    background-color: $important;
                    border: 2px solid $important;
                }
                .classe-item 
                {
                    margin: 1.25vw 0;
                    padding: 0.5vw 1.75vw;
                    background-color: #00bf63;
                    border: 2px solid #00bf63;
                }
                .theme-item 
                {
                    background-color: #ff5757;
                    border: 2px solid #ff5757;
                }
            }
        }
        .chapter-menu 
        {
            
            position: relative;
            left: 21%;
            top: -10%;
            display: flex; 
            flex-wrap: wrap;
            
            margin-left: 5vw;
            max-width: 51vw;
            
            .selected-chapter 
            {
                background-color: #f6f8fa !important;
                border: 2px solid black !important;
                color: black !important;
            }
            .chapter 
            {
                cursor: pointer;
                margin: 0.75vw 0 0 0.65vw;
                padding: 0.5vw 1vw;
                background-color: #ff5757;
                border: 2px solid #ff5757;
                border-radius: 2vw;
                font-weight: 600;
                color: $white;
                transition: all ease 0.3s;
            }
            .chapter-all
            { 
                cursor: pointer;
                margin: 0.75vw 0 0 0.5vw;
                padding: 0.5vw 1vw;
                border: 2px solid $important;
                background-color: $important;
                border-radius: 2vw;
                font-weight: 600;
                color: $white;
                transition: all ease 0.3s;
                transition: all ease 0.3s;
            }

        }
        
    }
    .slider-gauge 
    {
        position: absolute;
        bottom: 13%;
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 30vw;
        margin: 0vw auto;
    }
    .slider-wrapper 
    {
        position: relative;
        width: 100%;
    }
    .slider-value 
    {
        position: absolute;
        top: 1.5vw;
        transform: translateX(-46.3%);
        color: $important;
        font-size: 1vw;
        font-weight: bold;
        pointer-events: none;
    }
      
      .slider-labels {
        position: relative;
        top: -45%;
        z-index: -1;
        left: 1%;
        display: flex;
        justify-content: space-between;
        width: 108%;
        font-weight: bold;
        font-size: 1vw;
      }
      .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 6px;
        background: $black;
        outline: none;
        opacity: 1;
      }
      
      .slider:hover {
        opacity: 1;
      }
      
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $important;
        cursor: pointer;
      }
      
      .slider::-moz-range-thumb {
        width: 1.5vw;
        height: 1.5vw;
        border: 1px solid $important;
        border-radius: 50%;
        background: $important;
        cursor: pointer;
      }
}

.start-btn 
{
    position: absolute;
    bottom: 3%;
    cursor: pointer;
    padding: 1vw;
    background-color: #00bf63;
    color: $white;
    border-radius: 2vw;
    font-size: 1.4vw;
    font-weight: bold;
}