@import '../../0-app/variables.scss';

.resetMdp 
{
  margin-top: 1vw;

  font-size: clamp(13px, 1vw, 70px); // 100 px a verif
  span 
  {
    cursor: pointer;
    margin-left: 0.5vw;
  
    color: $important;
    text-decoration: underline;
  }
}

.sign-in-container 
{
  display: flex;
  flex-direction:column;
  
  margin: 3vw auto auto auto;
    
  width: 45vw;
  h2
  {
    font-size: 2vw; // 100px a verif
    margin: 1vw 0 ;
  }
  .double 
  {
    display: flex;
    align-items: center;
    
    margin-top: 2vw;
    
    height: 3vw;
    .group 
    {
      flex: 1;
      &:first-child
      {
        margin-right: 1vw;
      }
    }
  }
  button 
  {
    margin-top: 2vw;
    font-size: clamp(16px, 1.1vw, 100px); // 100px a verif
    width: 100%;
    height: 3vw;

    border-radius: 0.5vw;
    border: none;

    background-color: $important;
    color: $white;
    transition: all ease 0.3s;
    &:hover 
    {
      border-radius:2vw;
    }
  }
}