@import '../0-app/variables.scss';

.home
{
    margin-top: 15vw;
    font-family: 'Inter', sans-serif;
    .home-title
    {
        margin: 0 auto;
        
        width: 75vw;

        font-size: clamp(16px, 5.4vw, 280px);
        font-weight: 600;
        text-align: center;
    }
    .ia-color {
        color: $important;
    }
    .subtitle
    {
        margin: 3vw auto 0vw;
     
        width: 46vw;
        line-height:1.5vw;
        
        text-align: center;
        font-size: clamp(9px, 1.3vw, 70px);
        color: gray;
    }
    .discover-title 
    {
        margin: 3vw;
     
        font-size: clamp(17px, 2.2vw, 130px);
        font-weight: 500;
        text-align: center;
    }
    .video-container
    {
        margin: 0 auto;
        width: 60vw;
        .video
        {
            aspect-ratio: 16/9;
            width: 100%;
            border-radius: 1vw;
        }
    }        
    .separateur 
    {
        margin: 2vw auto;
        
        height: 1px;
        width: 50vw;
        
        background-color: $important;
    }
    .logcall-button 
    {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        margin: -2vw auto 3vw auto;
        
        border: none;
        height: 4vw;
        width: 16vw;
        border-radius:4vw;
        
        font-size: 1.8vw;
        background-color: $important;
        color: $white;
        transition: all ease 0.3s;
        &:hover 
        {
            transform: translateY(-7.5%);
        }
    }
   
}