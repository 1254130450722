@media screen and (max-width: 768px) {
    .audio-picker 
    {
        margin-top: 3vw;
        height: 7vw;
        .recorder
        {            
            margin-right: 0.5vw;
            padding-right: 6.5%;
            
            border-radius: 4vw;
            border: 1px solid #7d426f;
            height: 7vw;
            .recorder-btn
            {
                i   
                {
                    margin-left: 0.2vw;
                    font-size: 3vw;
                }
            }
        }
        .actif 
        {
            z-index: 100;
            justify-content: center;

            padding-right: 0;
            .close 
            {
                font-size: 4vw;
            }
        }
        .start-recording
        {

            font-size: 3vw;
           
        }
        @keyframes recording {
            0%
            {
                transform: translateY(0);
            }
            50% 
            {
                transform: translateY(-0.3vw);
            }
            100%
            {
                transform: translateY(0);
            }
        }
        .recording 
        {
            justify-content: flex-start !important; 
            .stop-recording
            {
                display: flex;
                align-items: center;
                position: absolute;

                padding-left: 3vw;
                font-size: 3vw;
                height: 100%;
                width: 100%;
            }
            .record-icon 
            {
                font-size: 3vw;
                margin-left: 80%;
            }
            .timer 
            {
                margin-left: 1vw;
                font-size: 3vw;
            }
        }
        .audio
        {
            .audio-input
            {
                padding: 0 0 0 3vw;
                font-size: 2.5vw;
                height: 7vw;
                border-radius: 4vw;
            }
        }
        .suppr-audio 
        {
            font-size: 4vw;
        }
    }
    .etat
    {
        margin: 1.5vw 0 5vw 0;
        font-size:2vw;
    }


.disabled .audio-input
{
    cursor: default !important;
}
}