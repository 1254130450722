@import '../../2-routes/0-app/variables.scss';
@mixin shrinkLabel {
    top: 0.5vw;

    font-size: 12px;
    color: black;
  }
.group {
  position: relative;

  .form-input {
    display: block;

    padding: 0 2% 0 1.75%;
    margin: 25px 0;
    
    height: 2.75vw;
    width: 96%;
    border: none;
    border-bottom: 1px solid grey;
    
    font-size: 18px;
    background-color: $white;
    color: grey;

    &:focus {
      outline: none;
    }
    &:focus ~ .form-input-label {
        @include shrinkLabel();
    }
  }
  .form-input-label {
    pointer-events: none;

    position: absolute;
    left: 0.7vw;
    top: 2.5vw;
    
    font-size: 16px;
    font-weight: normal;
    color: grey;
    transition: 300ms ease all;
    &.shrink {
        @include shrinkLabel();
    }
  }
}