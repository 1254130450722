@import '../0-app/variables.scss';
.desktopNav
{
    z-index: 1000;
    display: flex !important; 
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: 0;
    left: 2vw;

    height: 7vw;
    width: 96vw;

    color: #001524;
    background-color: $white;
    font-family: 'Inter', sans-serif;


    .focus-animation 
    {
        position: absolute;
        bottom: 35%;
        
        height: 2.5px;
        border-radius:10vw;
        width: 0;
        background-color: $important;
        transition: all ease 0.3s;
        &.home 
        {
            display: none;
        }
        &.analyse 
        {
            left: 54.15%;
            width:5.8vw;
        }
        &.colles
        {
            left: 63.4%;
            width: 5.9vw;
        }
        &.bibliotheque 
        {
            left: 72.5%;
            width: 8.6vw;
        }
        &.faq 
        {
            left: 84.35%;
            width: 2.18vw;
        }
    }


    .logo
    {
        margin-left: 1.5vw;

        font-size: 2vw;
        font-weight: 700;
        font-family: 'Paytone One', sans-serif;  
        span 
        {
            color: $important;
        }
    }
    .nav-items
    {
        display: flex;
        align-items: center;
        justify-content: space-around;
        
        margin-right: 3vw;

        font-size: 1.13vw;
        .item
        {
            margin-left: 3vw;
            transition: all ease 0.3s;
            a {
              opacity: 75%;
              &:hover 
              {
                opacity: 100%;
              }
            }
            &:hover
            {
                transform: translateY(-0.15vw);
            }
            .btn
            {
                margin: 0 1vw;
                padding: 0.4vw 1.3vw;

                border: 1px solid $secondary;
                border-radius: 0.4vw;

                opacity: 100%;
                font-size: clamp(12px, 1.28vw, 100px); // 100px a verif;
                background-color: $secondary;
                color: white;
                transition: all ease 0.35s;
            }
        }
    }
}