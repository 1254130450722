@import '../../0-app/variables.scss';



.array-container {
    display: flex;
    justify-content: center;
   
    margin: -3vw auto 2vw;
    .array 
    { 
        width: 70%;
        border-collapse: separate;
        border-spacing: 1vw;
        font-size: clamp(8px, 1.04vw, 60px);
        td:first-child,
        th
        {
            background-color: #D6EAF8;
        } 
        th,
        td 
        {
            padding: 1vw;
            text-align: center;
        }
        th 
        {
            border-top:none ;
            height: 2vw;

            font-size: clamp(9.5px, 1.3vw, 70px);
            vertical-align: middle;
        }
        td 
        {
            height: 1vw;
            width: 33%;
            line-height: 1.5vw;
            
            vertical-align: middle;
            text-align: left;
            background-color: #EBF5FB;
        }
        strong 
        {
            font-weight: 600;
        }
        th:first-child,
        td:first-child 
        {
            border-left: none;
        }
        th:last-child,
        td:last-child 
        {
            border-right: none;
        }    
        .paytone 
        {
            font-family: 'Paytone One', sans-serif;  

        }
    }
    .premiere 
    {
        background-color: transparent !important;
        border: none !important;
    }
}

@media screen and (max-width: 768px) {
    .array-container
    {
        .array 
        {
            width: 100% ;
            border-spacing: 1.5vw;
            td, th 
            {
                padding: 1.5vw;
            }
            td 
            {
                line-height: 3vw;
                width: 0%;
                text-align: center;
                &:first-of-type { text-align: left; }
            }
        }
    }
}