@import "../0-app/variables.scss";

.about
{
    margin:10vw 10vw 0;
    .title 
    {
        margin-bottom: 2vw;
        font-size: 3vw;
    }
    .question-container 
    {
        .question
        {
            margin-bottom: 3vw;
            padding: 1.5vw 1vw;

            min-height: 8vw;
            border-radius:1.5vw;
          
            color: $white;
            background-color: $black;
            .question-title 
            {
                display: inline-block;

                padding: 0.5vw 1vw;
                
                border-radius: 2vw;

                font-size: 1.5vw;
                font-weight: 500;
                color: #faf9f2;
                background-color: $important;
            }
            .question-answer
            {
                margin-top: 1.5vw;
                line-height: 1.8vw;
            }
            .nous-container 
            {
                display: flex;
                justify-content: space-evenly;
                .nous 
                {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img
                    {
                        height: 5.6vw;
                        border-radius: 100%;
                    }
                    h1
                    {
                        margin-top: 0.5vw;
                    }
                    h2 
                    {
                        font-size: 0.8vw;
                        margin-top: 0.3vw;
                    }
                    .icon-container 
                    {
                        display: flex;
                        flex-direction: row;
                        
                        margin-top: 0.5vw;
                        .icon-item
                        {
                            display: block;
                        
                            margin-bottom: 1.5vw;
                            padding-left: 0.6vw;
                            .icon
                            {
                                font-size: 1.5vw;
                                color: white;
                                transition: all ease 0.15s;
                            }
                            .icon:hover
                            {
                                transform: translateY(-0.15vw);
                            }
                        }   
                    }
                }
            }
        }
        .contact 
        {
            .contact-title 
            {
                background-color: $secondary;
            }
            form 
            {
                display:flex;
                align-items: center;
                flex-direction: column;
                
                margin: auto;
                width: 50%;
                input, textarea
                {
                    margin-top: 1vw;
                    padding: 0 1vw;

                    width: 30vw;
                    height: 3vw;
                    
                    border: 1px solid $white;
                    border-radius: 0.9vw;
                    
                    font-size: 1vw;
                    background-color: $white;
                }
                textarea 
                {
                    padding-top: 0.7vw;
                    
                    max-width: 30vw;
                    min-height: 5vw;
                }
                button 
                {
                    margin-top: 1vw;
                    
                    border-radius: 0.4vw;
                    border: none;
                    height: 2.5vw;
                    width: 32vw;

                    color: $white;
                    background-color: $important;
                }
            }
        }
    }
}