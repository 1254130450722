@media screen and (max-width: 768px) {
    .container 
    {
        width: 90%;
        border-radius: 2vw;
        padding: 2.5vw;
        .audio-picker 
        {
            margin-top: 0;
        }
    }
    
}