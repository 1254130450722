@media screen and (max-width: 768px) {
    .sign-in-container 
    {
        width: 90vw;
        h2 
        {
            margin: 10vw auto 5vw;
            font-size:7vw;
        }
        .double 
        { 
            margin-top: 10vw;
            height: 10vw;
        }
        button 
        {
            margin-top: 10vw;
            height: 10vw;
            border-radius: 1.5vw;
        }
    }
    .resetMdp 
    {
    margin-top: 2vw;
    span
    {
        margin-left: 1vw;
    }
    }
}