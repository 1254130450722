@import '../../2-routes/0-app/variables.scss';


@keyframes mouvement-bottom {
    from {
        transform: translateY(-10vw);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes mouvement-top {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(-4vw);
    }
}
@keyframes mouvement-right {
    from {
        left: 0;
    } to {
        left: 100%;
    }
}
@keyframes mouvement-left {
    from {
        right: 0;
    } to {
        right: 100%;
    }
}
@keyframes mouvement-centre-left {
    from {
        right: 100%;
    } to {
        right: 0;
    }
}
@keyframes mouvement-centre-right {
    from {
        left: 100%;
    } to {
        left: 0;
    }
}

.card-container.right 
{
    animation: mouvement-right 0.7s ease;
    left: 100%;
}
.card-container.left 
{
    animation: mouvement-left  0.7s ease;
    right: 100%;
}
.card-container.centre-left 
{
    animation: mouvement-centre-left  0.7s ease;
    right: 0;
}
.card-container.centre-right 
{
    animation: mouvement-centre-right  0.7s ease;
    left: 0;
}
.card-container 
{
    min-height: 84vh;
    height: auto !important;
    padding-bottom: 2vw;
    position: absolute;
    width:100vw;
    z-index: 0;
    
    .info 
    {
        position: absolute;
        left: 50%;
        bottom: 1vw;
        transform: translateX(-50%);
        font-style: italic;
        font-size: 0.75vw;
    }
    .card.verso-cacher 
    {
        display: none;
    }
    .card.verso 
    {
        z-index: -1;
        display: block;
        animation: mouvement-bottom 1s ease;

        transform: translateY(0);
        opacity: 1;
        .content 
        {
            text-align: left;
            padding:2vw;
            font-size: 1.1em; 
            height: auto;
        }
        transition: all ease 0.3s;

    }
    .card.move 
    {
        animation: mouvement-top 1s ease;
        transform: translateY(-4vw);
    }
    .card 
        {
            box-shadow: 4px 5px 0px 2px rgba(171, 174, 158, 0.56);
        position: relative;
        top: 0;
        margin: 0 auto;
        cursor: pointer;
        min-width: 50vw;
        width: 50vw;
        border: 3px solid #93add3;
        background-color: #93add3;
        border-radius: 2vw;
        transition: all ease 0.3s;
        &:first-child 
        {
            margin-top: 10vw;
        }
            .title-container 
            {
                display: flex;
                align-items: center;

                border-radius: 2vw;
                height: 3.5vw;
                font-family: 'Anton';
                font-size: 1.5vw;
                width: 100%;

                color: $white;
                .card-info 
                {
                    margin-left: 1vw;
                    text-shadow: #474747 1px 1px 5px;
                    .theme 
                    {
                        font-style: italic;
                    }
                }
                .annee 
                {
                    margin-right: 1.5vw;
                    margin-left: auto;
                    padding: 0.3vw 1vw;

                    border-radius: 2vw;
                    border: 2px solid $white;
                    background-color: #00bf63;
                }
            }
            .content 
            {

                display: flex;
                align-items: center;
                justify-content: center;
                
                padding:2vw;

                text-align: center;
                min-height: 2vw;
                height: auto;
                font-size: 1.2em;
                border-radius: 2vw;
                line-height:2vw;
                background-color: #f6f8fa;

                p 
                {
                    margin-bottom: 1vw;
                    &:last-child 
                    {
                        margin-bottom: 0;
                    }
                }
                .partieEntiere 
                {
                    width: auto;
                    height: 22vw;
                    background-image: url('../../2-routes/12-math/data/Partie_Entiere.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 50%;

                }
                .TAF 
                {
                    width: auto;
                    height: 18vw;
                    background-image: url('../../2-routes/12-math/data/TAF.png');
                    background-size: 70%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                .riemann
                {
                    width: auto;
                    height: 25vw;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 70%;

                    background-image: url('../../2-routes/12-math/data/Sommes_Riemann.png');
                }
                .rolle 
                {
                    width: auto;
                    height: 25vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 70%;
                    background-image: url('../../2-routes/12-math/data/Rolle.png');
                }
                .rectangle1 
                {
                    width: auto;
                    height: 25vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 95%;
                    background-image: url('../../2-routes/12-math/data/rectangle1.png');
                }
                .rectangle2 
                {
                    width: auto;
                    height: 25vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 95%;
                    background-image: url('../../2-routes/12-math/data/rectangle2.png');
                }
                .F_rectangle 
                {
                    width: auto;
                    height: 25vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-image: url('../../2-routes/12-math/data/F_rectangle.png');
                }
                .PolN 
                {
                    width: auto;
                    height: 30vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-image: url('../../2-routes/12-math/data/PolN.png');
                }
                .histY 
                {
                    width: auto;
                    height: 25vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-image: url('../../2-routes/12-math/data/histY.png');
                }
                ul, li
                {
                    list-style-type: disc !important;
                    margin-left : 1vw;
                    margin-bottom: 1vw;
                    text-align: left;
                }
            }
        }
} 


