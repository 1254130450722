@media screen and (max-width: 768px) {
    .pdf, .graph-container
    {
        width: 95vw;
        border-radius: 2vw;

        .user_subject 
        {
            margin: 2vw auto 6vw;
        }
    }
}