@import '../../2-routes/0-app/variables.scss';

.disabled 
{
    cursor:not-allowed ;
}
.container 
{
    margin: 0vw auto;
    padding: 1vw 1vw 1.4vw 1vw;

    width: 40%;
    
    background-color: $black;
    border-radius: 1vw;
    color: $white;
}
.graph-container 
{
    margin-top: 0vw;
    .download-btn 
    {
        right: 3%;
        top: 5%;
    }
}
.colle-disabled-container
{
    margin-top: 0vw !important;
    min-height: 0 !important;
}
.prompt-disabled 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    .prompt-title 
    {
        transform: translate(0);
        margin-bottom: 2vw;
    }
    .form 
    {
        transform: translate(0, 0);
        .container 
        {
            width: 100%;
            padding: 0;
            margin: 0vw 0 2vw;
        }
        .submit-btn 
        {
            height: 3.5vw;
        }
    }
}