@import "../0-app/variables.scss";

.vente 
{
    margin: 8vw 15.4vw 0;
    .title 
    {
        font-size: clamp(25px, 2.5vw, 100px); // a faire mieux
        text-align: center;
        font-weight: 600;
    }
    .blur-container 
    {
        z-index: 100000;
        position: fixed;
        top: 0;
        left: 0;
        backdrop-filter: blur(3px);
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        .email-verification 
        {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
            z-index: 10000;
            
            width: 30vw;
            height: 13vw;
            
            background-color: $black;
            color: white;
            border-radius: 1vw;
            i 
            {
                cursor: pointer;
                position: absolute;
                top: 6%;
                right: 4%;
            
                font-size: 1.5vw;
                color: red;
            }
            p 
            {
                text-align: center;
                font-size: 0.7vw;
                font-style: italic;
            }
            h1 
            {
                margin-top: 3vw;
    
                text-align: center;
                font-size: 1.3vw;
            }
            button 
            {
                position: relative;
                left: 50%;
                transform: translate(-50%);
                
                margin-top: 3vw;
                
                height: 2.5vw;
                border: none;
                border-radius:0.5vw;
                
                background-color: $important;
                color: white;
            }
        }
    }
    .card-vente-container 
    {
        display: flex !important;
        justify-content: space-evenly;
        
        margin-top:2.5vw;

        .sub-card 
        {
            position: relative;

            display: flex;
            flex-direction: column;
            padding: 2.8vw 2.8vw 6vw;
            
            border: 2px solid $important;
            border-radius: 2vw;
            width: 18.8vw;
            .card-title 
            {
                font-weight: 600;
                font-size: 1.5vw;
            }
            .card-description 
            {
                margin-top: 0.8vw;
                
                font-size: 1.2vw;
            }
            .card-price 
            {
                margin-top: 2vw;
                span 
                {
                    font-size: 2vw;
                    font-weight: 500;
                }
            }
            .features-container 
            {
                margin-top:2vw;
                .features 
                {
                    display: flex;
                    align-items: center;

                    margin-bottom: 1vw;
                    i 
                    {
                        font-size: 1.5vw;
                        color: $important;
                    }
                    i.fa-xmark 
                    {
                        color: $secondary;
                    }
                    p 
                    {
                        margin-left: 1vw;
                    
                        font-size: 1.1vw;
                    }
                    p sup {
                        font-size: x-small;
                        vertical-align: super;
                    }
                }
            }
            .card-buy 
            {
                position: absolute;
                bottom: 2vw;
                left: 50%;
                transform: translate(-50%);

                width: 19vw;
                height: 2.5vw;
                border-radius: 0.3vw;
                border: none;
                
                color: $white;
                background-color: $important;
                transition: all ease 0.3s;
                &:hover 
                {
                    transform: translate(-50%, -10%);
                }
            }
        }
    }
    .already-sub 
    {
        margin-top:23vw;
        text-align: center;
    }
    .toTop-btn 
    {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        margin: -2.5vw auto 3vw auto;
        
        border: none;
        height: 4vw;
        width: 16vw;
        border-radius:4vw;
        
        font-size: 1.8vw;
        background-color: $important;
        color: $white;
        transition: all ease 0.3s;
        &:hover 
        {
            transform: translateY(-7.5%);
        }
    }
    .caveat {
        margin-bottom: 2vw;

        font-size: medium;
        text-align: center;
    }
    .caveat sup {
        font-size: x-small;
        vertical-align: super;
    }
}