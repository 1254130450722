@import '../../0-app/variables.scss';

.error-message 
{
    margin-top: 0.5vw;

    font-size: 1.2vw;
    color: $secondary;
}
.sign-up-container 
{
    display: flex;
    flex-direction:column;
    
    margin: auto;
    
    width: 50vw;
    h2
    {
        margin: 1vw 0 ;
        font-size: 2vw;
    }
    .double 
    {
        display: flex;
        align-items: center;
    
        margin-top: 2.5vw;
        
        height: 3vw;
      
        .group 
        {
            flex: 1;
            &:first-child
            {
                margin-right: 1vw;
            }
        }
    }
    button 
    {
        margin-top: 3vw;
    
        width: 100%;
        height: 3vw;
        border-radius: 0.5vw;
        border: none;

        background-color: $important;
        color: $white;
        transition: all ease 0.3s;
        &:hover 
        {
            border-radius:2vw;
        }
    }
    .disabled-btn 
    {
        cursor: not-allowed !important;
        .loader 
        {
            margin: auto;
        }
    }
}