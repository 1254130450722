

.vente-descriptif 
{
    position: relative;
    .array-title 
    {
        margin-top: 3vw;
       
        text-align: center;
        font-size: clamp(13.5px, 2.5vw, 150px);
        font-weight: 500;
    }
    .logo-1 {
        font-weight: 100;
        font-family: Paytone One;
    }
    .why-container {
    
        margin: 5vw 0;
        .why-title 
        {
            text-align: center;
            font-size: clamp(19px, 4.5vw, 200px);
            font-weight: 500;
        }
        .why-content
        {
            margin: 2vw auto;

            max-width: 60%;
            line-height: 2vw;
            
            font-size: clamp(9px, 1.6vw, 70px);
            text-align: center;
        }
        .logo 
        {
            font-family: 'Paytone One', sans-serif;
        }
    }
}

@media screen and (max-width: 768px) {
    .vente-descriptif 
    {
        .array-title
        {
            margin-top: 7vw;
        }
        .why-container
        {
            margin: 8vw 0 ;
            .why-content
            {
                margin: 3vw auto;
                line-height: 4vw;
                max-width: 90%;
            }
        }
    }
}