@media screen and (max-width: 768px) {
    
    .about 
    {
        .team-container 
        {
            flex-wrap: wrap;
            img 
            {
                height: 20vw;
            }
            .team-mate
            {
                width: 100%;
                height: auto;
                h1
                {
                    margin-top: 4vw;
                    margin-bottom: 1.5vw;
                    font-size: 3vw;
                }
                h2 
                {
                    margin-bottom: 1vw;
                    color: #333;
                    font-size: 2vw;
                }
                .icon-container 
                {
                    .icon-item
                    {
                        margin-bottom: 2vw; 
                        .icon
                        {
                            font-size: 3vw;
                        }
                    }

                }
                p
                {
                    margin-bottom: 5vw;
                    max-width: 60%;
                    font-size: 2vw;
                    line-height: 3vw;
                }
            }
        }
        .contact
        {
            margin-top: 10vw;
            margin-bottom: 5vw;
            display: flex;
            flex-direction: column;
            label
            {
                text-align: center;
                font-size: 4vw;
                margin-bottom: 2vw;
            }
            input, textarea
            {
                margin-top: 3vw;
                padding: 0 3vw;
    
                width: 70vw;
                height: 7vw;
                border-radius: 4vw;
                
                font-size:3vw;
                background-color: transparent;

            }
            textarea
            {
                min-height: 21vw;
                padding-top: 3vw;
                margin: 4vw 0;
            }
            button
            {
                height: 7vw;

                border: none;
                border-radius: 3vw;
                
                font-size: 3vw;
                background-color: #0B6E4F;
                color: #faf9f2;
            }
        }
    }
}