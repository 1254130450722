@import '../0-app/variables.scss';
.responsive-alert
{
  margin: 40vw auto;
  padding: 10vw 0;

  text-align: center;
  line-height: 6vw;
  
  background-color: $black;
  color: $white;
}

.authentication-container
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 10vw;
  .login-signup
  {
    position: relative;
    left: -10vw;

    margin-top: 1vw;

    width: 30vw;
    font-size: clamp(13px, 1vw, 70px); // 100 px a verif
    span  
    {
      cursor: pointer;
      margin-left: 0.3vw;

      text-decoration: underline;
      color: $important;
    }
  }
  .login 
  {
    left: -7.5vw;
  }
  .email-verif-container 
  {
    padding: 2vw 2vw 1.8vw;
    margin-top: 8vw;

    border: 1px solid $important;
    border-radius:1.5vw;
    
    text-align: center;
    button 
    {
      margin-top: 2vw;
      padding: 0.5vw 2vw;
      
      border: none;
      border-radius: 0.5vw;
      color: $white;
      background-color: $important;
    }
  }
}


