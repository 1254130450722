@media screen and (max-width: 768px) {
    .vente-descriptif 
    {
        .array-title 
        {
            margin:11vw auto;
            font-size: 8vw;
            width: 100%;
        }
        .why-container 
        {
            .why-title 
            {
                font-size: 8vw;
                width: 90%;
                line-height: 11vw;
                margin: 7vw auto;
            }
            .why-content 
            {
                font-size: 4vw;
                line-height: 7vw;
                margin: 7vw auto;
            }
        }
    }
}