@import '../../2-routes/0-app/variables.scss';

.audio-picker 
{
    display: flex;
    align-items: center;
    position: relative;

    margin-top: 0.5vw;
    height: 3.5vw;
    .recorder
    {
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        
        position: absolute;
        
        margin-right: 0.5vw;
        padding-right: 6.5%;
        
        border-radius: 1.6vw;
        border: 1px solid $secondary;
        height: 3.4vw;
        width: 10%;

        background-color: $secondary;
        transition: all ease 0.5s;

        .recorder-btn
        {
            display: flex;
            align-items: center;
            justify-content: center;
            
            height: 3.5vw;
            width: 4vw;
            border-radius: 1.6vw;
            
            font-size: 1.3vw;
            i   
            {
                margin-left: 0.2vw;
            }
        }
    }
    .actif 
    {
        z-index: 100;
        justify-content: center;

        padding-right: 0;

        width: 100%;
        transition: all ease 0.5s;
        .close 
        {
            position: absolute;
            right: 3%;

            font-size: 1.3vw;
            transition: all 0.3s ease-in-out;
            &:hover 
            {
                transform: scale(1.3);
            }
        }
        &:hover 
        {
            border: 1px solid #faf9f2;
        }
    }
    .start-recording
    {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;

        height: 100%;
        width: 100%;
    }
    @keyframes recording {
        0%
        {
            transform: translateY(0);
        }
        50% 
        {
            transform: translateY(-0.3vw);
        }
        100%
        {
            transform: translateY(0);
        }
    }
    .recording 
    {
        justify-content: flex-start !important; 
        .stop-recording
        {
            display: flex;
            align-items: center;

            position: absolute;

            padding-left: 1vw;
            
            height: 100%;
            width: 100%;
        }
        .record-icon 
        {
            margin-left: 85%;
            
            color: red;
            
            animation: recording 1s infinite;
            transition: all ease 0.5s;
        }
        .timer 
        {
            margin-left: 0.5vw;
        }
    }
    .inactif
    {
        z-index: 0;
        transition: all ease 0.5s;
    }
    .audio
    {
        position: absolute;
        left: 10%;
        
        z-index: 1;
        width: 90%;
        .audio-input
        {
            cursor: pointer;
            display: flex;
            align-items: center;

            padding: 0 0 0 1vw;
            
            box-shadow: -2.5px 0px 0px 0px rgba(0,0,0,1);
            border-radius: 1.6vw;
            height: 3.5vw;
            
            background-color: $white;
            color: #001524;
        }
        transition: all ease 1s;
    }
    .suppr-audio 
    {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 3%;
        transform: translateY(-50%);

        z-index: 1;
        
        color: black;
        font-size: 1.3vw;
        transition: all 0.3s ease-in-out;
        &:hover 
        {
            transform: translateY(-50%) scale(1.3);
        }
    }
    .hide 
    {
        opacity: 0%;
        transition: all ease 0.5s;
    }
}
.etat
{
    margin: 0.5vw 0 1vw 0;
    font-size:0.8vw;
}
.disabled .audio-input
{
    cursor: default !important;
}