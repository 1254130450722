@mixin shrinkLabel {
    top: -4vw;

    font-size: 12px;
    color: black;
  }
@media screen and (max-width: 768px) {
    .group {
      position: relative;
    
      .form-input {
        margin: 0px 0;
        
        height: 10vw;
        width: 96%;
        border-bottom: 1px solid grey;
        
        font-size: 13px;
        color: grey;
    
        &:focus {
          outline: none;
        }
        &:focus ~ .form-input-label {
            @include shrinkLabel();
        }
      }
      .form-input-label {
        pointer-events: none;
    
        position: absolute;
        left: 0.7vw;
        top: 2.5vw;
        
        font-size: 15px;
        font-weight: normal;
        color: grey;
        transition: 300ms ease all;
        &.shrink {
            @include shrinkLabel();
        }
      }
    }
}