@import '../0-app/variables.scss';

.denied 
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);   
}
.colles-container, .colle-disabled-container
{
    display: flex;
    flex-direction: column;
    
    position: relative;
    
    margin: 10vw auto 0;
    padding: 2vw 2vw;
    
    width: 61vw;
    min-height: 20vw;
    border-radius: 1vw;
    
    background-color: $black;
    color: #faf9f2;
    .colle 
    {
        color: #faf9f2;
    }
    .colles-title 
    {
        justify-content: center;
        
        margin: 1vw auto ;
        
        max-width: 80%;
        line-height: 2.5vw;
        
        font-size: 2vw;
        text-align: center;
    
    }
    .colles-subtitle
    {
        display: inline-block;
        
        margin: 0.5vw 0;
        padding: 1vw;
        
        font-size: 1.2vw;
        background-color: $secondary;
        border-radius: 0.8vw;
    }   
    .cadre 
    {
        padding: 1vw;
        margin: 1vw 0;
        
        border: 1px solid $white;
        border-radius: 1vw;
        
        background-color: $white;
        color: black;
        transition: all ease 0.3s;
        .title 
        {
            display: inline-block;
            justify-content: center;

            padding: 0.5vw 1vw;
            margin-bottom: 0.75vw;
            
            border-radius: 2vw;
            
            font-size: clamp(12px, 1.5vw, 100px); // 100px a verifier
            font-weight: 500;
            color: #faf9f2;
            background-color: $important;
        }
        .content 
        {
            font-size: clamp(9px, 1.05vw, 100px); // a verif 100
        }
        .plan 
        {
            background-color:$green !important;
        }
        &:hover 
        {
            z-index: -1;
            transform: translate(-0.3vw, -0.3vw);
        }
    }
}