.sujet 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 19% auto 0 auto;

    width: 40vw;
    .select-container 
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .choice-btn-container-sujet 
    {
        display: flex;
        justify-content: center;

        margin-top: 2vw;
        .choice-btn
        {
            margin: 0 1vw;
        }
    }
    .choice-btn-container-annee
    {
        display: flex;
        justify-content: space-between;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .choice-btn 
        {
            height: 5vw;
            width: 20vw;
            border-radius: 5vw;
            border: none;
            
            
            font-size: 2vw;
            background-color: #001524;
            color: #faf9f2;
            transition: all ease 0.3s;
            &:first-child
            {
                margin-right: 2vw;
            }
            &:last-child
            {
                margin-left: 2vw;
            }
            &:hover 
            {
                box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.4);
                border: 1px solid #faf9f2;
            }
        }
    }
    .select-padding 
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        
        height: 6vw;
        width: 28%;
        border-radius: 1.3vw;
        
        background-color: #001524;
    }
    .back 
    {
        cursor: pointer;
     
        position: absolute;
        left: 5%;
        top: 19%;
        i 
        {
            font-size: 2vw;
        }
    }
}
.sujet-esh 
{
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p 
    {
        margin: 1vw 0;
    }
    .select-container 
    {
        padding:1.5vw 1.5vw;
        margin-left:1.25vw;
        
        border-radius: 1.3vw;

        background-color: #001524;
    }
}