@import '../0-app/variables.scss';

.navMobile 
{
    .logo 
    {
        margin-top: 6vw;
        margin-left: 4vw;
        font-size: 6vw;
    }
    .nav-items 
    {
        margin-top: 6.6vw;
        .item
        {
            .btn 
            {
                font-size: 4vw;
                padding: 1vw 2.5vw;
                border-radius: 1vw;
                margin: 0vw !important;
            }
        }
    }
  
}