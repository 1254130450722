@import '../../2-routes/0-app/variables.scss';



.math 
{
    left: 10% !important;
    width: 90vw !important;
    .content-container 
    {
        left: 45% !important;
    }
}
.not-sub-container 
{
    overflow: hidden;
    position: absolute;
    top: 9%;
    left: 8vw;
    
    z-index: 1000;
    
    height: calc(100vh - 4vw);
    width: 84vw;

    backdrop-filter: blur(15px);
   
    .content-container 
    {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        position: absolute; 
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        padding:  0 2vw;

        height: 18vw;
        width: 30vw;
        border-radius: 3vw;

        background-color: $black;
        box-shadow:
        2.8px 2.8px 2.2px rgba(255, 255, 255, 0.02),
        6.7px 6.7px 5.3px rgba(255, 255, 255, 0.028),
        12.5px 12.5px 10px rgba(255, 255, 255, 0.035),
        22.3px 22.3px 17.9px rgba(255, 255, 255, 0.042),
        41.8px 41.8px 33.4px rgba(255, 255, 255, 0.05),
        100px 100px 80px rgba(255, 255, 255, 0.07)
;
        .content 
        {            
            color: $white;
            text-align: center;
            font-size: 1.5vw;
        }
        .btn 
        {
            display: flex;
            justify-content: center;
            

            padding: 1vw;
    
            width: 18vw;
            border: none;
            border-radius: 0.5vw;
    
            background-color: $important;
            color: $white;
            font-size:1.5vw;
            
        }
        .not-sub-back 
        {
            position: absolute;
            top: -4vw;
            right: -26vw;
            font-size: 2vw;
        }
    }
    .content-container.border
    {
        border: 2px solid $white;
    }
}
.not-sub-container.full 
{
    left: 0;
    top: 0;

    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(8px);
}
