@media screen and (max-width: 768px) {
    .colles-container, .colle-disabled-container
    {
        width: 90vw;
        border-radius: 2vw;
        padding: 3vw;
        .colle 
        {

            .cadre 
            {
                padding: 1.75vw;
                border-radius: 2vw;
                .title 
                {
                    margin-bottom: 1.2vw;
                    border-radius: 4vw;
                    padding:0.75vw 1.5vw;
                }
            }
        }
    }
}