@media screen and (max-width: 768px) {
    .prompt-disabled 
    {
        .audio-picker 
        {
            margin-top: 1.5vw;
        }
    }
    
    .form
    {
        padding: 7.5vw 3vw 5vw;
        border-radius: 4vw;
        top: 45%;
        width: 90vw;
        .input
        {
            padding: 0 0 0 2vw;
            height: 7vw;
            border-radius: 4vw;

        }
        .submit-btn
        {
            margin-top: 2vw;
            font-size: 3vw;
            padding: 1vw;
        }
        .credit-error
        {
            margin-top:1vw;
            font-size: 2vw;
        }

    }
    .resultat-state
    {
        position: absolute;
        right: 0;
        left: 50%;
        transform: translate(-50%, -50%) ;
        width:  70vw;
        button
        {
            padding: 3vw;
            width: 100%;
            border-radius:3vw;
            font-size: 3vw;
            background-color: #0B6E4F;
            color: #faf9f2;
            border: none;
        }
        p
        {
            font-size: 3vw;
        }
    }
    .credit
    {
        top: 60%;
        font-size: 3vw;
    }
    .not-connected-prompt
    {
        top: 40%;
        p
        {
            font-size: 4vw;
        }
        button
        {
            margin-top: 5vw;

            border: none;
            border-radius: 1vw;
            height: 7.5vw;
            width: 45vw;
            font-size: 4vw;
        }
    }
}