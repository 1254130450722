@import '../0-app/variables.scss';
.payment-state-container
{
    .title 
    {
        margin-top: 15vw;

        font-size:2.5vw;
        font-weight: 600;
        text-align: center;
        .blue 
        {
            color: $important;
        }
    }
    .title-status 
    {
        margin-top: 15vw;
        
        font-size: 2vw;
        text-align: center;
    }
    .btn-container 
    {
        display: flex;
        justify-content: center;
    
        margin-top: 5vw;
        .btn 
        {
            margin:0 5vw;
        
            width: 27vw;
            height:3.8vw;
            border: none;
            border-radius: 2vw;
            
            font-size: 1.4vw;
            background-color: $important;
            color: white;
        }
    }
    .parrain 
    {
        margin: 5vw auto;
        
        text-align: center;
        .parrain-title 
        {
            margin-bottom: 2vw;
            font-size: 1.5vw;
        }
        input 
        {
            padding: 0.2vw;
            
            border: 1px solid $important;
            border-radius: 0.3vw;        
            height: 2vw;
        }
        .btn 
        {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            
            margin: 1vw auto;

            width: 10vw;
            height: 2.3vw;
            border-radius: 0.3vw;
           
            background-color: $important;
            color: white;
        }
    }
}