@import "../../2-routes/0-app/variables.scss";

.loading-content 
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loader {

    border: 5px solid transparent; /* Couleur de fond du loader */
    border-top: 5px solid $white; /* Couleur du rond */
    border-radius: 50%;
    
    width:1.5em;
    height: 1.5em;
}
.loop
{
    animation: loop 0.9s linear infinite;
}
.sacade 
{
    animation: sacade 1.2s linear infinite;
}
@keyframes sacade {
    0% { transform: rotate(0deg);}
    30% { transform: rotate(30deg)}
    70% { transform: rotate(355deg);}
    100% { transform: rotate(360deg);}
}
@keyframes loop {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}