@import '../0-app/variables.scss';


.loading 
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .loader 
    {
        border-top: 10px solid $black;
        height: 10vw;
        width: 10vw;
    }
}
.bibliotheque-container
{
    display: flex;
    flex-direction: column;

    position: relative;

    margin: 7.5vw auto 0;
    padding: 0 2vw;

    width: 61vw;
    min-height: 20vw;
    border-radius: 1vw;

    // background-color: $black;
    color: #faf9f2;
    .colle 
    {
        color: #faf9f2;
    }

    .colles-uppercontainer 
    {
        padding: 1vw 2vw 2vw;

        background-color: $black;
        border-radius: 1vw;
        .colles-title 
        {
            justify-content: center;
            
            margin: 1vw auto 1.5vw;
            
            max-width: 80%;
            line-height: 2.5vw;
            
            font-size: 2vw;
            text-align: center;

    }
        .row-container 
        {
            display: flex;
            align-items: center;
            justify-content: space-between;

            height: 3vw;
            .search-container 
            {
                input 
                {
                    padding: 0 0 0 0.75vw;
                    
                    width: 34.2vw;
                    width: 32vw;
                    height: 2.5vw;
                    border-radius: 0.8vw;
                    
                    color: $black;
                    font-size: 1vw;
                }
            }
            .filter 
            {
                position: relative;
                .label 
                {
                    position: absolute;
                    top: -1.1vw;
                    left: 0.3vw;

                    font-size: 0.7vw;
                }
                select 
                {
                    cursor: pointer;
                    padding: 0.5vw;
                    
                    height: 2.5vw;
                    min-width: 10vw;
                    border: none;
                    border-radius: 0.8vw;
                    
                    background-color: $white;
                }
            }
            .reset-filter-btn 
            {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                opacity: 1;
                
                height: 2.5vw;
                width: 2.5vw;
                border-radius: 0.8vw;
                
                font-size: 1.3vw;
                background-color: $white;
                color: $secondary;
            }
            .reset-filter-btn.disabled 
            {
                cursor: not-allowed;
                color: black;
            }
        }
    }
    .colles-subcontainer 
    {
        position: relative;

        padding: 2vw;
        margin-top: 2vw;

        border-radius: 1vw;
        min-height: 23vw;
        
        background-color: $black;
        .result-number
        {
            position: absolute;
            top: 1vw;
            right: 2vw;
            padding: 0.5vw;
            border-radius: 2vw;
            background-color: $green;
        }
        .colles-subtitle
        {
            display: inline-block;
            
            margin: 0.5vw 0;
            padding: 1vw;
            
            font-size: 1.2vw;
            background-color: $secondary;
            border-radius: 0.8vw;
        }   
        .cadre 
        {
            padding: 1vw;
            margin: 1vw 0;
            
            border: 1px solid $white;
            border-radius: 1vw;
            
            background-color: $white;
            color: black;
            transition: all ease 0.3s;
        
            .row 
            {
                display: flex;
                justify-content: space-between;
                .title 
                {
                    margin-bottom: 0.75vw;
                    
                    max-width: 45vw;
                    line-height: 2vw;
                    &::first-letter 
                    {
                        text-transform: capitalize;
                    }
                }
                .plan 
                {
                    background-color:#0B6E4F !important;
                }
            }
            &:hover 
            {
                z-index: -1;
                transform: translate(-0.3vw, -0.3vw);
            }
        
            .title 
            {
                display: inline-block;
                justify-content: center;
        
                padding: 0.5vw 1vw;
                
                border-radius: 2vw;
                font-size: 1.5vw;
                font-weight: 500;
                color: #faf9f2;
                background-color: $important;
            }
            .matiere 
            {
        
                display: flex;
                justify-content: center;
                align-items: center;
        
                padding: 0.5vw 1vw;
                
                border-radius: 2vw;
                height: 1.5vw;
                min-width:2.1vw;
                font-size: 1.2vw;
                font-weight: 400;
                color: #faf9f2;
                background-color: $secondary;
            }
        }
    }
}
