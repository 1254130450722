@import '../0-app/variables.scss';

@keyframes test {
0% {
    transform: translateY(0);
}   
50% {
    transform: translateY(-10%);
}   
0% {
    transform: translateY(0);
}   
}


.back 
{
    cursor: pointer;
    position: absolute;
    left: 5%;
    top: 19%;
    i 
    {
        font-size: 2vw;
    }
}
.back_second
{
    cursor: pointer;
    position: absolute;
    left: -15%;
    top: -190%;
    i 
    {
        font-size: 2vw;
    }   
}
.back_third
{
    cursor: pointer;
    position: absolute;
    top: -16.8vw;
    left: -44.82vw;
    i 
    {
        font-size: 2vw;
    }
}
.analyse 
{
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 100vh;
}
.analyse-container 
{
    display: flex;
    flex-direction: column;
    align-items: center;   
    .analyse-title 
    {
        position: relative;
        top: -3vw;

        font-size: 2vw;
        color: $black;
        &::after
        {
            content: "";
            position: absolute;
            bottom: -0.5vw;
            left: 0;

            width: 100%;
            height: 1.5px;
            background-color: $black;
        }
    }
    .choice-btn-container 
    {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 60vw;
    }
    .choice-btn-container-subject
    {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 55vw;
        button 
        {
            width: 23vw;
            font-size: 1.8vw;
        }
    }
    .choice-btn 
    {
        margin: 2vw 2vw 0;
        height: 5vw;
        width: 20vw;
        border: 1px solid $black;
        border-radius: 5vw;
        
        font-size: 2vw;
        background-color: $black;
        color: $white;
        transition: all ease 0.3s;
        &:hover 
        {
            box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.4);
            border: none;
            // animation: test 1s infinite;
            transform: translateY(-10%);
            transition : all ease 0.5s; 
        }
    }
    .footer-container
    {
        position: absolute;
        bottom: 0;
    }
}

.new 
{
    position: relative;
    &::before 
    {
        content: "Nouveau !";
        position: absolute;
        top: 0.3vw;
        left: -0.3vw;
        font-size: 1vw;
        color: red;
        transform: rotate(-25deg);
    }
}