@import '../../2-routes/0-app/variables.scss'; 

.prompt-title 
{
    top: 19%;
    left: 50%;
    transform: translateX(-50%);

    letter-spacing: 0.1vw;
    font-size: clamp(16px, 2vw, 100px); // 100px a verif
    font-weight: 400;
}
.choice-btn-container-subject 
    {
        display: flex;
        justify-content: space-between;
    
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        width: 50%;
    }
    .choice-btn 
    {
        height: 5vw;
        width: 23vw;
        border-radius: 1vw;
        border: none;
    
        font-size: 1.8vw;
        background-color: $black;
        color: #faf9f2;
        transition: all ease 0.3s;
        &:hover 
        {
            box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.4);
            border: 1px solid #faf9f2;
        }
    }
.absolute 
{
    position: absolute;
}
.form
{
    display: flex;
    flex-direction: column;

    top: 52.5%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 4vw 3vw 3lvw;
    
    border-radius: 1vw;
    width: 40vw;

    font-family: 'Inter', sans-serif;
    color: #faf9f2;
    background-color: $black;
    .input
    {
        padding: 0 0 0 1vw;
    
        height: 3.5vw;
        border: none;
        border-radius: 1.4vw;
    
        
        font-size: clamp(10.5px, 1.1vw, 100px); //100px a verifier 
        outline-color: $important;
        background-color: $white;
        color: $black;
        &::placeholder
        {
            color: $black;
            opacity: 90%;
        }
    }
    .submit-btn
    {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 1Svw;
    
        height: 3.5vw;
        border: 1px solid $important;
        border-radius: 4vw;
        
        color: white;
        background-color: $important;
        &:hover 
        {
          box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.4);
          border: 1px solid #faf9f2;
        }
    }
    .disabled
    {
        cursor:not-allowed !important;
    }
    .btn-special 
    {
        font-size: 1.1vw;
    }
    .red
    {
        color: #DC143C !important;
    }
    .credit-error
    {
        position: relative;
        top: 0.5vw;
        left: 0.4vw;
     
        color: #DC143C;
        font-size: 0.9vw;
    }
}
.resultat-state
{
    position: absolute;
    top: 50%;
    right: 15.5vw;
    transform: translate(50%, -50%);

    font-family: 'Inter', sans-serif;
    button
    {
        padding: 1vw;

        border-radius: 0.5vw;
        border: none;

        background-color: #0B6E4F;
        color: #faf9f2;
    }
    p
    {
        font-size: 1.2vw;
    }
}
.credit
{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);

    font-family: 'Inter', sans-serif;
}
.sub-btn 
{
    position: absolute;
    top: 88%;
    left: 50%;
    transform: translateX(-50%);

    padding: 0.5vw 1.5vw;
    
    border-radius: 0.3vw;
    border: none;

    color: white;
    background-color: $important;
}
.not-connected-prompt
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
    p
    {
        text-decoration: underline;
    }
    button
    {
        margin:1vw;

        border: none;
        border-radius: 0.5vw;
        height: 3vw;
        width: 20vw;

        color: white;
        background-color: #0B6E4F;
    }
}