@import '../0-app//variables.scss';
.checkout 
{
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 8vw;
    
    min-height: calc(100vh - 8vw);
}
.checkout-error
{
    margin-top: 20vw;
    
    text-align: center;
}
.disclaimer 
{
    display: block;
    
    margin: 2vw 0 1vw !important;
    
    text-align: center !important;
    font-size: 1.3vw;
    a 
    {
        text-decoration: underline;
        color: $important;
    }
}